<template>
  <div class="test">
    <h3>{{ (test || '').description }}</h3>
    <question
      v-for="(question, index) in questions"
      :key="question._id"
      :question="question"
      :position="index + 1"
      :submitted="submitted"
      @change="change"
    />

    <slot></slot>

    <el-button
      icon="el-icon-d-arrow-right"
      v-if="!submitted"
      @click="submitTest({ status: true })"
      type="primary"
      round
    >
    Заврши
    </el-button>

    <el-button
      icon="el-icon-d-arrow-left"
      v-else
      @click="goToTestsPage"
      type="primary"
      round
    >
    Назад
    </el-button>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex';
import Question from './Question';

export default {
  name: "tests",
  components: {
    Question,
  },
  props: {
    test: {
      type: Object
    }
  },
  computed: {
    questions() {
      return this.test ? this.test.questions : [];
    },
    submitted() {
      return this.test ? this.test.submitted : false;
    }
  },
  methods: {
    ...mapMutations([
      'updateTestAnswer'
    ]),
    ...mapActions([
      'submitTest'
    ]),
    change({ question, option }) {
      this.updateTestAnswer({question, option});
    },
    goToTestsPage() {
      this.$router.push({
        name: 'tests'
      });
    }
  }
};
</script>

<style lang="scss">
.test {
  padding-left: 10px;

  img {
    max-width: 100%;
  }

  ol {
    padding-inline-start: 0;
  }
}
</style>
