<template>
  <div class="home">
    <el-row :gutter="20">
      <el-col
        :xs="{ span: 24, offset: 0 }"
        :span="6"
      >
        <test-progress
          class="test-progress"
          :progress="progress"
          v-if="test && test.submitted"
        />
        &nbsp;
      </el-col>
      <el-col
        :span="24"
        :xs="{ span: 24, offset: 0 }"
      >
        <test
          :test="test"
          v-if="test"
        >
          <div
            class="progress-circle"
            v-if="isMobile && test && test.submitted"
          >
            <test-progress
              class="test-progress"
              :progress="progress"
              type="circle"
            />
            <span>Проценат успешности: {{ progress }}%</span>
          </div>
        </test>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MobileDetect from 'mobile-detect';

import store from './../store'
import Test from './../components/tests/Test';
import TestProgress from './../components/tests/Progress';

export default {
  name: "tests",
  components: {
    Test,
    TestProgress
  },
  data() {
    return {
      isMobile: false
    }
  },
  computed: {
    ...mapGetters({
      test: 'getTest',
      progress: 'getTestProgress'
    })
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch('getTest', { id: to.params.testId });
    next();
  },
  mounted() {
    const mobileDetect = new MobileDetect(window.navigator.userAgent);
    this.isMobile = mobileDetect.mobile();
  }
};
</script>

<style lang="scss">
.test-progress {
  position: fixed;
  width: 200px;
  padding-top: 1rem;
  padding-bottom: 1rem;

  @media screen and (max-width: 600px) {
    position: relative;
    width: 100%;
  }
}

.progress-circle {
  text-align: center;
  padding-bottom: 1rem;

  .el-progress {
    display: flex;
    justify-content: center;
  }
}
</style>
