<template>
  <el-progress
    :text-inside="true"
    :stroke-width="24"
    :percentage="roundedProgress"
    :status="progressType"
    :type="type"
  ></el-progress>
</template>


<script>
import ElProgress from 'element-ui/lib/progress';

export default {
  name: 'Progress',
  components: {
    ElProgress
  },
  props: {
    progress: {
      type: Number
    },
    type: {
      type: String,
      default: 'line'
    }
  },
  computed: {
    progressType() {
      if (this.progress > 80) {
        return 'success';
      }

      if (this.progress > 50) {
        return 'warning';
      }

      return 'exception'
    },
    roundedProgress() {
      return Number(this.progress.toFixed(2));
    }
  }
}
</script>
